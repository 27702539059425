import { queryClient } from "./clients"

import "@mantine/charts/styles.css"
import { ColorSchemeScript, MantineProvider, createTheme } from "@mantine/core"
import "@mantine/core/styles.css"
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"
import { QueryClientProvider } from "@tanstack/react-query"

const theme = createTheme({
  white: "#fefefe",
  black: "#222222",
  colors: {
    green: [
      "#e5fffb",
      "#d0fff5",
      "#a1fde9",
      "#6ffcdd",
      "#4cfcd4",
      "#39fcce",
      "#2cfcca",
      "#1ee0b2",
      "#02c79d",
      "#00ad87"
    ],
    blue: ["#e9f0ff", "#d0dcff", "#a0b6fa", "#6d8df6", "#436bf3", "#2855f1", "#174af1", "#073cd7", "#0035c1", "#002dab"]
  },
  primaryColor: "green",
  primaryShade: 8
})

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <MantineProvider theme={theme}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </MantineProvider>
        <ScrollRestoration />
        <Scripts />
        <ColorSchemeScript />
      </body>
    </html>
  )
}

// export const ErrorBoundary = () => {
//   const error = useRouteError()
//   captureRemixErrorBoundaryError(error)
//   return <div>Something went wrong</div>
// }

export default function App() {
  return <Outlet />
}
